import React, { useState } from 'react'
import PropertyCard from './property-card'
import 'hover.css'
import { useSelector, useDispatch } from 'react-redux'
import {
  setProperties,
} from '../../actions'
// import { API_KEY } from '../../env'
import axios from 'axios'

function RenderList({ mdColumn, lgColumn, properties, limit = null }) {
  const dispatch = useDispatch()

  const { metaInfo } = useSelector((state) => ({
    metaInfo: state.properties.metaInfo
  }))
  console.log("🚀 ~ const{metaInfo}=useSelector ~ metaInfo:", metaInfo)

  const pagination = Math.ceil(metaInfo.total_count / metaInfo.limit)
  const [page, setPage] = useState(1)

  const pageTo = async (p) => {
    let loading = true
    let url = localStorage.getItem("fetchUrl")
    window.scrollTo({ top: 450, behavior: 'smooth' })
    url = `${url}&offset=${p * 12}`
    try {
      const { data } = await axios.get(url)
      dispatch(setProperties(data.objects))
      setPage(p + 1)
    } catch (error) {
      console.error('FETCHING PROPERTIES: ', error)
    } finally {
      loading = false
    }
  }

  if (limit) {
    // ? si la prop "limit" existe hace un slice para reducir el número de elementos
    return properties.slice(0, limit).map((property) => (
      <div
        key={property.id}
        className={`col-xs-10 col-sm-10 col-md-${mdColumn} col-md-${lgColumn} item`}>
        <PropertyCard {...property} />
      </div>
    ))
  } else {
    return (
      <>
        <div className='row mx-auto justify-content-center'>
          {properties.map((property) => (
            <div
              key={property.id}
              className={`col-xs-10 col-sm-10 col-md-${mdColumn} col-md-${lgColumn} item`}
            >
              <PropertyCard {...property} />
            </div>
          ))
          }
        </div>
        <div className='row mx-auto justify-content-center'>
          {pagination && pagination > 0 &&
            <nav>
              <ul className="pagination justify-content-end mt-3 mr-4">
                {
                  Array.from(Array(pagination), (element, index) => {
                    return <li className={`page-item ${(page === index + 1) ? 'active' : ''}`} key={index} >
                      <a className='page-link' onClick={() => pageTo(index)}> {(index + 1)} </a>
                    </li>
                  })
                }
              </ul>
            </nav>
          }
        </div>
      </>
    )
  }
}

function PropertyList(props) {
  return (
    <div className='row'>
      <RenderList {...props} />
    </div>
  )
}

export default PropertyList
